/* eslint-disable */

'use strict'
import $ from "jquery";
import UIkit from "UIkit";
import Inputmask from "inputmask";
// import {  TweenMax} from "gsap";
// import datetimepicker from "jquery-datetimepicker";
import marquee from "jquery.marquee";

import 'lazysizes';
// import Swiper from 'swiper';
import Swiper from 'swiper/bundle';
// import { tree } from "gulp";
import Scrollbar from 'smooth-scrollbar';

// import {
//   ymap as ymap
// } from './map-contact';
// import {ymap as ymapHowDoMetro} from './map-how-do-metro';
// import {ymap as ymapHowDoAuto} from './map-how-do-auto';

// $('.klademug').each(function () {
//   $(this).prop('Counter', 0).animate({
//     Counter: 100
//   }, {
//     duration: 2000,
//     easing: 'swing',
//     step: function (now) {
//       $(this).text(Math.ceil(now) + '%');
//     }
//   });
// });

// функция возвращает cookie с именем name, если есть, если нет, то undefined    
// function getCookie(name) {
//   let matches = document.cookie.match(new RegExp(
//   "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
//   ));
//   return matches ? decodeURIComponent(matches[1]) : undefined;
// }
// let cookiecook = getCookie("cookiecook"),
// cookiewin = document.getElementsByClassName('cookie-warning')[0];    
// // проверяем, есть ли у нас cookie, с которой мы не показываем окно и если нет, запускаем показ
// if (cookiecook != "no") {
//   // показываем    
//   cookiewin.style.display="block"; 
//   // закрываем по клику
//   document.getElementById("cookie-close-2").addEventListener("click", function(){
//     cookiewin.style.display="none";    
//     // записываем cookie на 1 день, с которой мы не показываем окно
//     let date = new Date;
//     date.setDate(date.getDate() + 30);    
//     document.cookie = "cookiecook=no; path=/; expires=" + date.toUTCString();               
//   });
// }


setTimeout(() => {
  let preloaderEl1 = document.getElementById('preloader1');
  $('body').attr('style', '');
  if(preloaderEl1) {
    preloaderEl1.classList.add('hidden');

    $('.main-banner__slider').attr("uk-slider", "finite:false;clsActivated: uk-transition-active;velocity:0;autoplay: true;autoplay-interval: 6000;pause-on-hover:false;");
    $('.header-top__item1 .uk-container').addClass('uk-animation-slide-top');

    $('.main-banner-bottom__wrap1').addClass('uk-animation-slide-bottom');
    $('.main-banner-bottom__wrap2').addClass('uk-animation-slide-bottom');
    $('.main-banner-bottom__wrap3').addClass('uk-animation-slide-bottom');
  }

}, 1000);

if ($(window).width() > 639) {
  setTimeout(() => {
    let preloaderEl2 = document.getElementById('preloader2');

    $('body').attr('style', '');
    if (preloaderEl2) {
      preloaderEl2.classList.add('hidden');
      
      $('.main-banner__slider').attr("uk-slider", "finite:false;clsActivated: uk-transition-active;velocity:0;autoplay: true;autoplay-interval: 6000;pause-on-hover:false;");
      $('.header-top__item1 .uk-container').addClass('uk-animation-slide-top');

      $('.main-banner-bottom__wrap1').addClass('uk-animation-slide-bottom');
      $('.main-banner-bottom__wrap2').addClass('uk-animation-slide-bottom');
      $('.main-banner-bottom__wrap3').addClass('uk-animation-slide-bottom');
    }
    


  }, 3000);

} else {
  setTimeout(() => {
    let preloaderEl2 = document.getElementById('preloader2');
    $('body').attr('style', '');
    if (preloaderEl2) {
      preloaderEl2.classList.add('hidden');

      $('.main-banner__slider').attr("uk-slider", "finite:false;clsActivated: uk-transition-active;velocity:0;autoplay: true;autoplay-interval: 6000;pause-on-hover:false;");
      // $('.header-top__item1 .uk-container').attr('uk-scrollspy', 'cls:uk-animation-slide-top; delay: 500;');
      $('.header-top__item1 .uk-container').addClass('uk-animation-slide-top');

      $('.main-banner-bottom__wrap1').addClass('uk-animation-slide-bottom');
      $('.main-banner-bottom__wrap2').addClass('uk-animation-slide-bottom');
      $('.main-banner-bottom__wrap3').addClass('uk-animation-slide-bottom');
    }


    


  }, 1000);

}




$(document).ready(function () {
  if ($(window).width() <= 959) {
    $('.clients__slider').attr('uk-slider', 'autoplay: true;autoplay-interval: 3000;pause-on-hover:false;');
  } else {
    $('.clients__slider').attr('uk-slider', 'center:true;autoplay: true;autoplay-interval: 3000;pause-on-hover:false;');
  }
  

  UIkit.util.on('.header-top__item1', 'active', function () {
    $('body').addClass('sticky-active');
  });
  UIkit.util.on('.header-top__item1', 'inactive', function () {
    $('body').removeClass('sticky-active');
  });

  // Вывод карты на странице контакты
  // if ($('.map-contact').length > 0) {
  //   ymap();
  // }

 



  Array.from(document.querySelectorAll('.work-slider-js')).map(el => {
    const elSlider = el.querySelector('.work__slider');
    const elSliderNext = el.querySelector('.work-slider__arrow-right');
    const elSliderPrev = el.querySelector('.work-slider__arrow-left');
    const elSliderScrollbar = el.querySelector('.work-slider__scrollbar');

    const workSlider = new Swiper(elSlider, {
      spaceBetween: 20,
      slidesPerView: 1,

      navigation: {
        nextEl: elSliderNext,
        prevEl: elSliderPrev,
      },
      scrollbar: {
        el: elSliderScrollbar,
        hide: false,
        draggable: true
      },
      breakpoints: {
        // when window width is >= 320px
        639: {
          spaceBetween: 30,
          slidesPerView: 1.2,
        },
        959: {
          spaceBetween: 50,
          slidesPerView: 2.2,
        },
        1450: {
          spaceBetween: 50,
          slidesPerView: 3,

        },
      },
    });
  });




  $('.marque-headline_1').marquee({
    //Если вы хотите всегда анимировать с помощью jQuery
    allowCss3Support: true,
    //работает, когда allowCss3Support имеет значение true - полный список см.http://www.w3.org/TR/2013/WD-css3-transitions-20131119/#transition-timing-function
    css3easing: 'linear',
    //Требуется плагин JQuery. По умолчанию это «линейный»
    easing: 'linear',
    //время паузы до следующего поворота анимации в миллисекундах
    delayBeforeStart: 200,
    //'left', 'right', 'up' or 'down'
    direction: 'left',
    //true или false - следует ли дублировать выделение, чтобы показать эффект продолжения потока
    duplicated: true,
    //скорость в миллисекундах
    duration: 70000,
    //разрыв в пикселях между тикерами
    gap: 0,
    //пауза
    pauseOnCycle: false,
    //при наведении курсора на паузу - с помощью плагина jQuery https://github.com/tobia/Pause
    // pauseOnHover: true,
    startVisible: true
  });


  if ($(window).width() > 1199) {
    Array.from(document.querySelectorAll('.js-parralax-1')).map(el => {
      window.addEventListener('mousemove', function (e) {
        let x = e.clientX / window.innerWidth;
        let y = e.clientY / window.innerHeight;
        el.style.transform = 'translate(-' + x * 50 + 'px, -' + y * 50 + 'px)';
      });
    });
    let bg = document.querySelector('.parralax-js');
    let bg2 = document.querySelector('.parralax-2-js');
    window.addEventListener('mousemove', function (e) {
      let x = e.clientX / window.innerWidth;
      let y = e.clientY / window.innerHeight;
      if (bg) {
        bg.style.transform = 'translate(-' + x * 100 + 'px, -' + y * 100 + 'px)';
      }
      if (bg2) {
        bg2.style.transform = 'translate(-' + x * 25 + 'px, -' + y * 25 + 'px)';
      }
    });
  }


  const sliderCounter = document.querySelectorAll('.slider__counter');
  sliderCounter.forEach(element => {
    //Счетчик слайда яхт
    UIkit.util.on(element, 'itemshown', function (e) {
      // $('#yacht__slider1 .slider_number').text(e.detail[0].index + 1);
      $(element).find('.slider_number').text(('0' + (e.detail[0].index + 1)).slice(0));

    });
  });



  function clock() {
    var d = new Date();
    var day = d.getDate();
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();

    var month = new Array("января", "февраля", "марта", "апреля", "мая", "июня",
      "июля", "августа", "сентября", "октября", "ноября", "декабря");
    var month_en = new Array("january", "february", "marth", "april", "may", "june",
      "jule", "august", "september", "october", "november", "december");
    var days = new Array("Воскресенье", "Понедельник", "Вторник", "Среда",
      "Четверг", "Пятница", "Суббота");

    if (day <= 9) day = "0" + day;
    if (hours <= 9) hours = "0" + hours;
    if (minutes <= 9) minutes = "0" + minutes;
    if (seconds <= 9) seconds = "0" + seconds;

    var date_date = day + " " + month[d.getMonth()] + " " + d.getFullYear() + ' г.';
    var date_date2 = day + " " + month[d.getMonth()] + ' ';
    var date_date_en = day + " " + month_en[d.getMonth()] + " " + d.getFullYear() + ' y.';
    var date_time2 = hours + "<span class='anim-dott'>:</span>" + minutes + "<span class='anim-dott'>:</span>" + seconds;
    var date_time = hours + "<span class='anim-dott'>:</span>" + minutes;

    if (document.layers) {
      document.layers.date.document.write(date_time);
      document.layers.date.document.close();
      document.layers.time.document.write(date_time);
      document.layers.time.document.close();
    } else {
      if (document.getElementById("day")) {
        document.getElementById("day").innerHTML = date_date;
      }
      if (document.getElementById("day2")) {
        document.getElementById("day2").innerHTML = date_date2;
      }
      if (document.getElementById("day-en")) {
        document.getElementById("day-en").innerHTML = date_date_en;
      }
      if (document.getElementById("time")) {
        document.getElementById("time").innerHTML = date_time;
      }
      if (document.getElementById("time-menu")) {
        document.getElementById("time-menu").innerHTML = date_time;
      }
      if (document.getElementById("time2")) {
        document.getElementById("time2").innerHTML = date_time2;
      }


    }
    setTimeout(clock, 1000);
  }
  clock();


  // видео обычное
  $('.video-js').parent().click(function () {
    if ($(this).children(".video").get(0).paused) {
      $(this).children(".video").get(0).play();
      $(this).children(".playpause").fadeOut();
    } else {
      $(this).children(".video").get(0).pause();
      $(this).children(".playpause").fadeIn();
    }
  });

  // видео ютуб
  var video = {
    init: function () {
      video.play();
    },
    play: function () {
      $('body').on('click', '#play-video-btn', function (e) {
        video.setSrc(this);
      });
    },
    setSrc: function (el) {
      if (el) {
        var video_id = $(el).data('id');
        var url = '//www.youtube.com/embed/' + video_id + '?enablejsapi=1&version=3&showinfo=0&playerapiid=ytplayer&autoplay=1';
        $('img#embed-video-preview').hide();
        $('#play-video-btn').hide();
        $('iframe#embed-video-frame').attr('src', url).fadeIn();
      }
    }
  }
  video.init();




  // Маска телефона
  if ($('.f_phone').length > 0) {
    Inputmask({
      "mask": "+7 (999) 999-9999"
    }).mask('.f_phone');
  } else {
    console.log('not found')
  }

  let inputScrollNum = 1;
  // Инпут года
  const inputArchive = document.querySelectorAll('.input_archive .select');

  inputArchive.forEach(element => {

    const $this = $(element)
    const $styledSelect = $(element).find('.select-styled');
    const $listItems = $(element).find('li');
    const $list = $(element).find('.select-options');
    const inputScrollNumCurrent = inputScrollNum;

    $list.attr('id', `inputScroll${inputScrollNumCurrent}`)
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
      });
      $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

      let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), {
        alwaysShowTracks: true
      });
      inputScroll.update();
    });
    $listItems.click(function (e) {
      $styledSelect.text($(this).text()).removeClass('active');
      $('.select-options').fadeOut('fast');
    });
    $(document).on('touchstart mousedown', function (e) {
      if ($this.has(e.target).length === 0) {
        $list.fadeOut('fast');
        $styledSelect.removeClass('active');
      }
    });
    inputScrollNum++;

  });





  //  Стилизация селекта
  $('.input-js select').each(function () {
    let inputScrollNumCurrent = inputScrollNum;
    let $this = $(this),
      numberOfOptions = $(this).children('option').length;
    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');
    let $styledSelect = $this.next('div.select-styled');
    // $styledSelect.text($this.attr('select_name'));
    $this.attr('select_name') != '' ? $styledSelect.text($this.attr('select_name')) : $styledSelect.text($('option:selected', this).text());

    let $list = $('<ul />', {
      'id': 'inputScroll' + inputScrollNumCurrent,
      'class': 'select-options'
    }).insertAfter($styledSelect);
    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }
    let $listItems = $list.children('li');
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
      });
      $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

      console.log(inputScrollNumCurrent);
      if ($(`#inputScroll${inputScrollNumCurrent}`).height() >= 160) {
        let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), {
          alwaysShowTracks: true
        });
        inputScroll.update();
      }

    });
    $listItems.click(function (e) {
      $styledSelect.addClass('select-styled-black');
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();
      $("input[type='submit'].filterApply").trigger('click');
    });
    $(document).on('touchstart mousedown', function (e) {
      if ($.contains(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), e.target) === false) {
        $list.hide();
        $styledSelect.removeClass('active');
      }
    });
    inputScrollNum++;

  });


  $('#file-input').change(function () {
    let maxFileSize = 10000000;
    let file = this.files[0];
    if (this.files && file) {
      var reader = new FileReader();
      if (file.size >= maxFileSize) {
        alert("Вес файла должен быть меньше 10 МБайт");
        this.value  = "";
      } else {
        console.log(file.size);
        reader.readAsDataURL(this.files[0]);
      }
    }
  });

  $('#file-input2').change(function () {
    let maxFileSize = 10000000;
    let file = this.files[0];
    if (this.files && file) {
      var reader = new FileReader();
      if (file.size >= maxFileSize) {
        alert("Вес файла должен быть меньше 10 МБайт");
        this.value  = "";
      } else {
        console.log(file.size);
        reader.readAsDataURL(this.files[0]);
      }
    }
  });




  //стилизация бургера меню
  function menuCheck() {
    var menu_attr = $("#offcanvas-nav").hasClass("uk-open");
    if (menu_attr) {
      $('.header_top_menu').addClass('open');
    } else {
      $('.header_top_menu').removeClass('open');
    };
  };

  UIkit.util.on('#offcanvas-nav', 'shown', function () {
    menuCheck();

  });
  UIkit.util.on('#offcanvas-nav', 'hidden', function () {
    menuCheck();
  });




  $(".menu-list a[uk-scroll]").click(function () {
    UIkit.offcanvas('#offcanvas-nav').hide();
  });



  $("a.transition").click(function (event) {
    event.preventDefault();
    var linkLocation = this.href;
    $("html").css('background', '#1D1D1D');
    $("body").fadeOut(700, redirectPage);

    function redirectPage() {
      window.location = linkLocation;
    }
  });








  function getDataRentForm(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    var captcha = grecaptcha.getResponse(widgetId1);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    if (!captcha.length) {
      // Выводим сообщение об ошибке
      $('#recaptchaError2').text('* Вы не прошли проверку "Я не робот"');
    } else {
      // получаем элемент, содержащий капчу
      $('#recaptchaError2').text('');
      $.ajax({
        url: action,
        type: "POST",
        cache: false,
        processData: false,
        contentType: false,
        data: new FormData(this),
        beforeSend: function() {
          UIkit.modal('#modal-calling').hide();
          UIkit.modal('#succes_rent_loading').show();
        },
        success: function () {
          UIkit.modal('#succes_rent_loading').hide();
          $('#zay_form')[0].reset();
          $('a[href="#modal-calling"]').attr('href', '#success')
          UIkit.modal('#success').show();
          setTimeout(function () {
            UIkit.modal('#success').hide();
          }, 3000);
        }
      });
    }
  };
  $('form#zay_form').on('submit', getDataRentForm);

  function getDataRentForm2(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    var captcha2 = grecaptcha.getResponse(widgetId2);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    if (!captcha2.length) {
      // Выводим сообщение об ошибке
      $('#recaptchaError3').text('* Вы не прошли проверку "Я не робот"');
    } else {
      // получаем элемент, содержащий капчу
      $('#recaptchaError3').text('');
      $.ajax({
        url: action,
        type: "POST",
        data: dataForm,
        success: function () {
          UIkit.modal('#modal-calling2').hide();
          $('a[href="#modal-calling2"]').attr('href', '#success')
          UIkit.modal('#success').show();
          setTimeout(function () {
            UIkit.modal('#success').hide();
          }, 3000);
        }
      });
    }
  };
  $('form#zay_form2').on('submit', getDataRentForm2);

  function getDataRentForm3(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    var captcha3 = grecaptcha.getResponse(widgetId3);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    if (!captcha3.length) {
      // Выводим сообщение об ошибке
      $('#recaptchaError4').text('* Вы не прошли проверку "Я не робот"');
    } else {
      // получаем элемент, содержащий капчу
      $('#recaptchaError4').text('');
      $.ajax({
        url: action,
        type: "POST",
        cache: false,
        processData: false,
        contentType: false,
        data: new FormData(this),
        beforeSend: function() {
          UIkit.modal('#succes_rent_loading').show();
        },
        success: function () {
          UIkit.modal('#succes_rent_loading').hide();
          $('#zay_form3')[0].reset();
          UIkit.modal('#success').show();
          setTimeout(function () {
            UIkit.modal('#success').hide();
          }, 3000);
        }
      });
    }
  };
  $('form#zay_form3').on('submit', getDataRentForm3);


});







// Анимация до якоря при переходе к другой странице
document.body.scrollTop = 0;
var windowScrolled = false;
$(document).ready(function (e) {
  if (windowScrolled != false || location.hash.length == 0) return;

  setTimeout(() => {
    var name = location.hash.substr(1);
    var name2 = name.split(';');

    if (name2[1] === undefined) {
      name2[1] = 0;
    }
    console.log(name);
    console.log(name2);
    var el = $('#' + name2[0] + ', [name=' + name2[0] + ']');

    console.log(el);

    if (el.length > 0) {
      $('body, html').animate({
        scrollTop: el.offset().top - name2[1]
      }, 1000);

      window.scrolled = true;
    }
  }, 1500);

});